import React, { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Flex, Icon, MenuBar } from '@zeal/web-ui';

import { CollapseButton } from '@app/AppContainer/components/StyledComponents';
import { getAppRoutes } from '@app/utils/appRoutes';
import { usePageStore } from '@app/store/pageStore';
import { useTourStore } from '@app/store/tourStore';
import Tour from '@app/Tour/Tour';

import MenuLinks from './MenuLinks/MenuLinks';
import AppMenuBanner from './AppMenuBanner/AppMenuBanner';
import { useLocation } from 'react-router-dom';

function MenuHeader() {
	return (
		<img
			alt="zeal logo"
			className="w-28"
			src="https://myzeal.app/assets/imgs/logo.png"
		/>
	);
}

function MenuFooter() {
	return (
		<Flex align="center" justify="center" className="mb-4">
			<AppMenuBanner />
			{/*<Avatar.WithInfo*/}
			{/*  userTitle="COO"*/}
			{/*  userName="Foo Bar"*/}
			{/*  userImg="https://randomuser.me/api/portraits/lego/1.jpg"*/}
			{/*/>*/}
			{/* <Button  size="xs" iconEnd="signOut" onClick={doLogout}>
				Logout
			</Button> */}
		</Flex>
	);
}

type AppMenuProps = {
	className?: string;
	children?: React.ReactNode;
	isCollapsed: boolean;
	gridArea: string;
};

const { ownAppMenus, appMainMenus, sectionedMenus } = getAppRoutes();

function AppMenu({ isCollapsed, ...props }: AppMenuProps) {
	const { toggleAppMenu } = usePageStore();
	const { i18n } = useTranslation();
	const { t } = useTranslation('freeHome');

	const { setActiveTour, activeTour } = useTourStore();

	const location = useLocation();
	React.useEffect(() => {
		if (location.pathname === '/home') {
			setActiveTour('home');
		}
	}, [activeTour, location, setActiveTour]);

	const handleMenuToggle = useCallback(() => {
		toggleAppMenu();
	}, [toggleAppMenu]);

	return (
		<MenuBar
			className={props.className}
			headerContent={<MenuHeader />}
			footerContent={<MenuFooter />}
			isCollapsed={isCollapsed}
			id="home-sidebar"
		>
			<MenuLinks
				ownAppMenus={ownAppMenus}
				appMainMenus={appMainMenus}
				sectionedMenus={sectionedMenus}
			/>
			<CollapseButton onClick={handleMenuToggle} dir={i18n.dir()}>
				<Flex
					justify="center"
					align="center"
					style={{
						width: '100%',
					}}
				>
					<Icon
						iconName={isCollapsed ? 'chevronDoubleRight' : 'chevronDoubleLeft'}
						size="xs"
						variant="light"
						className="m-auto"
					/>
				</Flex>
			</CollapseButton>
			{/* {activeTour === 'home' && (
				<Tour t={t} elementId="home-sidebar" stepName="home" />
			)} */}
		</MenuBar>
	);
}

const StyledAppMenu = styled(AppMenu)`
	grid-area: ${({ gridArea }) => gridArea};
`;

export default memo(StyledAppMenu);

import { ACLBoundaryProps } from '@app/ACL/ACLBoundary';
import { FeatureKey } from '@app/FeatureControl/types';
import { IconNames } from '@zeal/web-ui';
import { LazyExoticComponent } from 'react';
import { RouteProps } from 'react-router-dom';

export enum RouteFlags {
	SHOW_REDEMPTIONS_DISABLED = 'SHOW_REDEMPTIONS_DISABLED',
	SHOW_RECEIPTS_DISABLED = 'SHOW_RECEIPTS_DISABLED',
	NI_MERCHANT = 'NI_MERCHANT',
	GIFT_FRIEND_DISABLED = 'GIFT_FRIEND_DISABLED',
}

type MenuSections = 'cardlink' | 'business';

interface ExternalModuleOptions {
	external: true;
	path: string;
}

interface InternalModuleOptions {
	external?: false;
	apiKey: FeatureKey;
	exact?: boolean;
	menuOrder: number | false;
	ownApp: boolean;
	path: string;
	label: string;
	iconName: IconNames;
	subMenu?: SubMenuOptions[];
	excludedFor: RouteFlags[];
	flag?: string;
	isFeatured?: boolean;
	menuSection?: MenuSections;
}

interface BasePathOptions {
	path: string;
	disabled?: boolean;
	external?: boolean;
}

export type TPathOptions = BasePathOptions &
	(ExternalModuleOptions | InternalModuleOptions);

export type SubMenuOptions = {
	label: string;
	path: string;
	excludedFor?: RouteFlags[];
	isExcluded?: ({ businessProperties }: { businessProperties: any }) => boolean;
	featureApiKey?: FeatureKey;
	isFeatured?: boolean;
};

interface RouteConfig extends Omit<RouteProps, 'element' | 'children'> {
	element?: RouteProps['element'] | LazyExoticComponent<any>;
	children?: RouteConfig[];
	aclProps?: ACLBoundaryProps;
	featureApiKey?: FeatureKey;
}

export type IModuleOptions = {
	routes: RouteConfig[];
} & TPathOptions;
